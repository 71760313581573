<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="12"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h1
                  class="custom-header-title"
                >
                  {{ request.stringID }} - {{ request.customer ? request.customer.name : '' }}
                </h1>
                <p class="log_info">
                  Created on {{ dateFormatWithTime(request.createdAt) }}<br>Last updated <span v-if="request.approvedBy">by {{ request.approvedBy.name }}</span> on {{ dateFormatWithTime(request.updatedAt) }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <section class="invoice-preview-wrapper">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <div
            class="user_block ac_type mb-2"
            style="float: none;"
          >
            <div class="user_avatar" />
            <div class="user_info">
              <h6>Status</h6>
              <h5 class="text-capitalize">
                {{ request.status }}
              </h5>
            </div>
          </div>
          <div
            v-if="request.status == 'rejected'"
            class="reject_not"
          >
            <b-card>
              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="mr-3"
                >
                  <feather-icon
                    icon="MessageSquareIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  Remarks
                </h3>
              </template>
              <b-card-text
                class="mb-0"
              >
                <strong>{{ request.approvedBy.name }} has rejected this Customer Request.</strong>
                <span class="notetext">
                  {{ request.rejectNote }}
                </span>
              </b-card-text>
            </b-card>
          </div>
          <b-card v-if="request.status == 'awaiting approval' || request.status == 'pending'">
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                  class="white-color"
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                Ready to Approve?
              </h3>
            </template>
            <b-form-checkbox
              v-model="secondAgree"
              value="accepted"
              unchecked-value=""
              class="custom-control-primary"
            >
              I have verified the requested changes and that the customer's signature matches our records.
            </b-form-checkbox>

            <!-- Button: Send Invoice -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mb-75"
              block
              :disabled="secondAgree == '' || !canViewThisAction('approve', 'CustomerRequest')"
              @click="approveRequest()"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Approve</span>
            </b-button>
            <b-button
              v-if="canViewThisAction('reject', 'CustomerRequest')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-danger"
              class="mb-75"
              block
              @click="rejectRequest()"
            >
              <feather-icon
                icon="XIcon"
                class="mr-50"
                size="16"
              />
              <span class="align-middle">Reject</span>
            </b-button>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class=""
            title="Customer Requested Changes"
          >
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <div class="d-flex mb-2">
                    <b-card-title class="custom-header-title">
                      Customer Requested Changes
                    </b-card-title>
                    <b-button
                      v-if="request.status == 'awaiting approval'"
                      variant="link"
                      class="btn-icon rounded-circle ml-auto mr-1 flat-primary-bg"
                      @click="openEditModal"
                    >
                      <feather-icon icon="EditIcon" /> Edit
                    </b-button>
                  </div>
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased small-padding-table d-none d-md-block"
                  >
                    <b-tbody v-if="request.pendingArray">
                      <b-tr>
                        <b-th>
                          Home Phone No.
                        </b-th>
                        <b-td>
                          <del v-if="request.pendingArray.includes('Home Number')">{{ request.prevHomeTel }}</del>
                          <span v-else>{{ request.prevHomeTel }}</span>
                        </b-td>
                        <td>
                          <feather-icon
                            v-if="request.pendingArray.includes('Home Number')"
                            icon="ArrowRightIcon"
                            size="18"
                            class="align-middle text-body"
                          />
                        </td>
                        <b-td>
                          <span v-if="request.pendingArray.includes('Home Number')">{{ request.homeTel }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>
                          Residential Address
                        </b-th>
                        <b-td style="max-width: 250px;">
                          <del v-if="request.pendingArray.includes('Residential Address')">{{ request.prevPerAdd1 }}, {{ request.prevPerAdd2 }}, {{ request.prevPerCountry }} {{ request.prevPerPostcode }}</del>
                          <span v-else>{{ request.prevPerAdd1 }}, {{ request.prevPerAdd2 }}, {{ request.prevPerCountry }} {{ request.prevPerPostcode }}</span>
                        </b-td>
                        <td>
                          <feather-icon
                            v-if="request.pendingArray.includes('Residential Address')"
                            icon="ArrowRightIcon"
                            size="18"
                            class="align-middle text-body"
                          />
                        </td>
                        <b-td style="max-width: 250px;">
                          <span v-if="request.pendingArray.includes('Residential Address')">{{ request.perAdd1 }}, {{ request.perAdd2 }}, {{ request.perCountry }} {{ request.perPostcode }}</span>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>
                          Mailing Address
                        </b-th>
                        <b-td style="max-width: 250px;">
                          <del v-if="request.pendingArray.includes('Mailing Address')">{{ request.prevCorAdd1 }}, {{ request.prevCorAdd2 }}, {{ request.prevCorCountry }} {{ request.prevCorPostcode }}</del>
                          <span v-else>{{ request.prevCorAdd1 }}, {{ request.prevCorAdd2 }}, {{ request.prevCorCountry }} {{ request.prevCorPostcode }}</span>
                        </b-td>
                        <td>
                          <feather-icon
                            v-if="request.pendingArray.includes('Mailing Address')"
                            icon="ArrowRightIcon"
                            size="18"
                            class="align-middle text-body"
                          />
                        </td>
                        <b-td style="max-width: 250px;">
                          <span v-if="request.pendingArray.includes('Mailing Address')">{{ request.corAdd1 }}, {{ request.corAdd2 }}, {{ request.corCountry }} {{ request.corPostcode }}</span>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>

                  <hr>

                  <div class="d-flex mb-2 mt-3">
                    <b-card-title class="custom-header-title">
                      Customer Details
                    </b-card-title>
                  </div>
                  <b-table-simple
                    borderless
                    responsive
                    class="particulars_of_deceased small-padding-table d-none d-md-block"
                  >
                    <b-tbody>
                      <b-tr>
                        <b-th width="230px">
                          Name
                        </b-th>
                        <b-td class="text-uppercase">
                          {{ request.customer ? request.customer.name : '-' }}
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          NRIC
                        </b-th>
                        <b-td>{{ request.customer ? request.customer.nric : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Date of Birth
                        </b-th>
                        <b-td>{{ (request.customer && request.customer.dob) ? dateFormat(request.customer.dob) : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Sex
                        </b-th>
                        <b-td>{{ request.customer ? request.customer.sex : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Race
                        </b-th>
                        <b-td>{{ request.customer ? request.customer.race : '-' }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th width="230px">
                          Nationality
                        </b-th>
                        <b-td>{{ request.customer ? request.customer.nationality : '-' }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>

              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="4"
                  md="4"
                  class="mt-md-1 mt-1 without__bg"
                  order="2"
                  order-md="1"
                >
                  <b-card-text class="mb-0 signatories_block">
                    <div
                      class="sign-box"
                      style="border: 1px solid rgb(210, 220, 234);"
                    >
                      <div v-if="request.customer">
                        <!-- <b-avatar
                          v-if="request.customer.image"
                          :src="request.customer.image"
                        /> -->
                        <b-avatar
                          class="avatar-text-bg"
                          :text="resolveAcronymName(request.customer.name)"
                        />
                      </div>
                      <div>
                        <div v-if="request.signature">
                          <b-img
                            class="mb-1 mb-sm-0 user__avatar"
                            height="50"
                            :src="request.signature"
                          />
                        </div>
                      </div>
                    </div>
                    <p
                      class="ml-75 semi-bold"
                      style="font-weight: bold;"
                    >
                      Customer Signature & Date
                    </p>
                    <p class="ml-75">
                      {{ request.customer ? request.customer.name : '' }} <span class="dot-divider">&bull;</span> {{ request.updatedAt ? dateFormatWithFormatParam(request.updatedAt, 'DD MMM YYYY') : '-' }}
                    </p>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-card
        v-if="canViewThisAction('read', 'File')"
        title="Related Files"
        class="mb-3 mt-3 p-2"
      >
        <b-card-text>
          <RelatedFilesList :related-files="relatedFiles" />
        </b-card-text>
      </b-card>
    </section>

    <b-modal
      id="modal-update-intended-user"
      ref="update_user_modal"
      modal-class="status-update-modal iu-modal"
      footer-class="iu-edit-modal-footer"
      cancel-variant="flat-primary"
      ok-variant="primary"
      ok-title="Save Changes"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Edit"
      size="sm"
      @ok="submitEditForm"
    >
      <b-form @submit.prevent="submitEditForm">
        <validation-observer
          ref="customerEditForm"
        >
          <b-form-group
            label="Home Phone No."
            label-for="h-iu-homeTel"
          >
            <cleave
              id="h-iu-homeTel"
              v-model="homeTel"
              class="form-control"
              :raw="false"
              :options="options.prefix"
            />
          </b-form-group>
          <b-form-group
            label="Residential Address"
            label-for="h-iu-perAdd1"
          >
            <b-form-input
              id="h-iu-perAdd1"
              v-model="perAdd1"
              placeholder="Address Line 1"
              name="perAdd1"
            />
          </b-form-group>
          <b-form-group
            label-for="h-iu-perAdd2"
          >
            <b-form-input
              id="h-iu-perAdd2"
              v-model="perAdd2"
              placeholder="Address Line 2"
              name="perAdd2"
            />
          </b-form-group>
          <b-form-group
            label-for="h-iu-perCountry"
          >
            <v-select
              id="h-iu-perCountry"
              v-model="perCountry"
              :options="countryOptions"
              :clearable="false"
              placeholder="Select a country"
            />
          </b-form-group>
          <b-form-group
            label-for="h-iu-perPostcode"
          >
            <b-form-input
              id="h-iu-perPostcode"
              v-model="perPostcode"
              placeholder="Post Code"
              name="perPostcode"
            />
          </b-form-group>
          <b-form-group
            label="Mailing Address"
            label-for="h-iu-corAdd1"
          >
            <b-form-input
              id="h-iu-corAdd1"
              v-model="corAdd1"
              placeholder="Address Line 1"
              name="corAdd1"
            />
          </b-form-group>
          <b-form-group
            label-for="h-iu-corAdd2"
          >
            <b-form-input
              id="h-iu-corAdd2"
              v-model="corAdd2"
              placeholder="Address Line 2"
              name="corAdd2"
            />
          </b-form-group>
          <b-form-group
            label-for="h-iu-corCountry"
          >
            <v-select
              id="h-iu-corCountry"
              v-model="corCountry"
              :options="countryOptions"
              :clearable="false"
              placeholder="Select a country"
            />
          </b-form-group>
          <b-form-group
            label-for="h-iu-corPostcode"
          >
            <b-form-input
              id="h-iu-corPostcode"
              v-model="corPostcode"
              placeholder="Post Code"
              name="corPostcode"
            />
          </b-form-group>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <span class="align-middle">Save Changes</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTr, BTh, BTd, BTbody, BImg, VBTooltip, BForm,
  BCardText, BAvatar, BFormCheckbox, BCardBody, BCardTitle, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import RelatedFilesList from './RelatedFilesList.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BImg,
    BCardText,
    BAvatar,
    BFormCheckbox,
    BCardBody,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    Cleave,
    vSelect,
    ValidationObserver,
    RelatedFilesList,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      rejectNote: '',
      secondAgree: '',
      homeTel: '',
      perAdd1: '',
      perAdd2: '',
      perCountry: '',
      perPostcode: '',
      corAdd1: '',
      corAdd2: '',
      corCountry: '',
      corPostcode: '',
      countryOptions: ['Singapore'],
      options: {
        prefix: {
          numericOnly: true,
          prefix: '+65',
          blocks: [3, 8],
        },
      },
      required,

      request: {},
      relatedFiles: [],
    }
  },
  created() {
    this.$http.get(`customer/customer/profile-change/${this.$route.params.id}/show`)
      .then(response => {
        this.request = response.data.data
        this.relatedFiles = response.data.relatedFiles
        this.homeTel = this.request.homeTel
        this.perAdd1 = this.request.perAdd1
        this.perAdd2 = this.request.perAdd2
        this.perCountry = this.request.perCountry
        this.perPostcode = this.request.perPostcode
        this.corAdd1 = this.request.corAdd1
        this.corAdd2 = this.request.corAdd2
        this.corCountry = this.request.corCountry
        this.corPostcode = this.request.corPostcode
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    approveRequest() {
      this.$swal({
        title: 'Approve this Customer Request?',
        html: 'You are going to approve this Customer Request. Are you sure?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/success.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, cancel',
        confirmButtonText: 'Yes, proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.updateChange('approved')
          }
        })
    },

    rejectRequest() {
      this.$swal({
        title: 'Are you sure you want to <br>reject this request?',
        html: `Once you reject this request <strong>${this.request.stringID}</strong>, <br>customer will be informed.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/file-cross.png'),
        input: 'textarea',
        inputPlaceholder: 'Add Remarks',
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, reject request',
        cancelButtonText: 'No, go back',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
          input: 'reject-note-ta',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.rejectNote = result.value
            this.updateChange('rejected')
          }
        })
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },

    updateChange(status) {
      let title = ''
      if (status === 'approved') {
        title = 'Change Approved'
      } else {
        title = 'Change Rejected'
      }
      const formData = new FormData()
      formData.append('status', status)
      formData.append('rejectNote', this.rejectNote)

      this.$http.post(`customer/customer/profile-change/${this.$route.params.id}/approve`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.request = response.data.data
          this.$swal({
            title,
            html: '',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/success.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            showCancelButton: false,
            confirmButtonText: 'Okay',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    async openEditModal() {
      this.$refs.update_user_modal.show()
      await this.$nextTick()
      this.homeTel = this.request.homeTel
      this.perAdd1 = this.request.perAdd1
      this.perAdd2 = this.request.perAdd2
      this.perPostcode = this.request.perPostcode
      this.perCountry = this.request.perCountry
      this.corAdd1 = this.request.corAdd1
      this.corAdd2 = this.request.corAdd2
      this.corPostcode = this.request.corPostcode
      this.corCountry = this.request.corCountry
    },
    submitEditForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.customerEditForm.validate().then(success => {
        if (success) {
          this.$refs.update_user_modal.hide()
          this.$swal({
            title: 'Save current changes?',
            html: 'Your changes will overwrite the current information, are you sure?',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/icons/warning.png'),
            imageWidth: 80,
            imageHeight: 80,
            imageAlt: 'Custom Icon',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: 'Yes, Save Changes',
            cancelButtonText: 'No, go back',
            customClass: {
              confirmButton: 'btn btn-primary ml-1',
              cancelButton: 'btn btn-outline-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.value) {
                const formData = new FormData()
                formData.append('request', this.request._id)
                formData.append('customer', this.request.customer._id)
                formData.append('status', 'approved')
                formData.append('homeTel', this.homeTel)
                formData.append('perAdd1', this.perAdd1)
                formData.append('perAdd2', this.perAdd2)
                formData.append('perCountry', this.perCountry)
                formData.append('perPostcode', this.perPostcode)
                formData.append('corAdd1', this.corAdd1)
                formData.append('corAdd2', this.corAdd2)
                formData.append('corCountry', this.corCountry)
                formData.append('corPostcode', this.corPostcode)

                this.$http.post('customer/customer/edit/overwrite', formData, {
                  headers: { 'Content-Type': 'multipart/form-data' },
                })
                  .then(response => {
                    if (response.data.success) {
                      this.request = response.data.data
                      this.$swal({
                        title: 'Changes Saved',
                        html: '',
                        // eslint-disable-next-line global-require
                        imageUrl: require('@/assets/images/icons/success.png'),
                        imageWidth: 80,
                        imageHeight: 80,
                        imageAlt: 'Custom Icon',
                        showCancelButton: false,
                        confirmButtonText: 'Okay',
                        customClass: {
                          confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                      })
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: response.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
                  .catch(error => {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
  .per-page-selector {
    width: 90px;
  }
  .badge.light-font {
    font-weight: 400;
  }
  .modal-header.payment-status.front-modal {
    background: #21BB8B;
    padding: 24px 30px !important;
    button {
      background: rgba(255, 255, 255, 0.3) !important;
      margin-right: 20px !important;
      padding: 0;
      border-radius: 5px;
      min-width: 40px;
      width: 40px;
      line-height: 40px;
      height: 40px;
      position: relative;
    }
    h3 {
      font-size: 18px;
      line-height: 21px;
      color: #fff;
    }
    span.step-class {
      color: #fff !important;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 23px;
      font-size: 12px;
      display: block;
    }
    .feather {
      vertical-align: text-bottom;
      color: #fff;
    }
  }
  .btn-success-without-button {
    border-color: #21BB8B !important;
    background-color: #21BB8B !important;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
